<template>
  <template v-if="dbSynced">
    <router-view />
  </template>
</template>

<script>
import initialLoad from "@/viewController/App";
import validate from "@/general/helperMethods/validate";

export default {
  data: () => {
    return {
      axiosInstance: null,
    };
  },
  async created() {
    this.gp_url = validate.getParameterByName("gp_url");

    if (window.location.href.split("/")[3] == "login") {
      this.dbSynced = true;
      return;
    } else if (window.location.href.split("/")[3] == "announcement") {
      if (this.store.b2b.uid == null || this.store.b2b.uid == "") {
        this.store.b2b.uid = window.location.href.split("/")[6];
      }
    } else if (window.location.href.split("/")[3]) {
      this.store.b2b.uid = window.location.href.split("/")[3];
    }

    await initialLoad.loadDataFromDBInStateManager(this.store.b2b.uid);
    await initialLoad.setAuth();
    this.dbSynced = true;
  },
  computed: {
    store: function () {
      return this.$store.state;
    },
    dbSynced: {
      get() {
        return this.store.environment.dbSynced;
      },
      set(value) {
        this.store.environment.dbSynced = value;
      },
    },
    switchOption: {
      get() {
        return this.store.environment.switchOption;
      },
      set(value) {
        this.store.environment.switchOption = value;
      },
    },
    gp_url: {
      get() {
        return this.store.environment.gp_url;
      },
      set(value) {
        this.store.environment.gp_url = value;
      },
    },
  },
};
</script>

<style></style>
